<template>
    <div class="row">
      <div class="col-md-12">
        <div class="bgc-white bd bdrs-3 p-20 mB-20">
          <h2 class="c-grey-900 mB-20" style="display: inline-block">
            {{ $t("subscriptions") }}
          </h2>
          <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
        <button class="btn btn-sm btn-success" @click="addSubscription">
          <sui-icon name="add"  /> {{ $t('addSubscription') }}
        </button>
          <div id="dataTable_wrapper" class="dataTables_wrapper">
            <table
              id="dataTable"
              class="table table-bordered dataTable table-hover"
              role="grid"
              aria-describedby="dataTable_info"
              style="width: 100%"
              width="100%"
              cellspacing="0"
            >
              <thead>
                  <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="Name: activate to sort column descending"
                >
                  #
                </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Position: activate to sort column ascending"
                  >
                    {{ $t("name") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Position: activate to sort column ascending"
                  >
                    {{ $t("price") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Position: activate to sort column ascending"
                  >
                    {{ $t("durationByMonth") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Position: activate to sort column ascending"
                    style="width:100px"
                  >
                    {{ $t("edit") }}
                  </th>
                </tr>
              </thead>
              <tbody v-show="!loading" v-for="item in subscriptions" :key="item.id">
                <tr role="row" class="odd">
                  <td class="sorting_1">{{ item.id }}</td>
                  <td>
                    {{getTranslation(item.ar_name,item.en_name) }}
                  </td>
                  <td style="direction: ltr;color:green">
                    {{ item.price + ' '+$t('QAR')}}
                  </td>

                  <td>
                    {{ item.duration + ' '+$t('month')}}
                  </td>
                  <td>
                  <button type="button" @click="editSubscription(item)" class="btn btn-primary">{{ $t('edit') }}</button>
                </td>
                  
                </tr>
              </tbody>
            </table>
            <loading v-show="loading" />
            <p v-show="!subscriptions.length" style="text-align: center">
              {{ $t("noData") }}
            </p>
            <div style="text-align:center;margin-top:40px">
              <pagination style="display:inline-block;text-align:center" v-model="page" :records="pagination.total ?pagination.total : 0 " :per-page="40" @paginate="getSubscriptions"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: true,
        statusLoading: true,
        key: "",
        subscriptions: [],
        pagination: {},
        branches: [],
        page:1,
      };
    },
    created() {
      this.getSubscriptions();
    },
    methods: {
      getRole() {
      return sessionStorage.getItem('role');
    },
      getTranslation(ar , en) {
        return localStorage.getItem('lang') == 'ar' ? ar : en;
      },
      checkForm: function (e) {
        e.preventDefault();
        this.getSubscriptions();
      },
      getSubscriptions: function () {
        this.loading = true;
        this.$http
          .get("/backend/subscriptions/get", {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
            params: {
              key : this.key,
            },
          })
          .then(
            function (data) {
              this.loading = false;
              switch (data.status) {
                case 200:
                  this.subscriptions = data.body.data;
                  this.pagination = data.body;
                  console.log(this.pagination);
                  break;
              }
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
                  break;
              }
            }
          );
      },
      addSubscription: function () {
        const panel1Handle = this.$showPanel({
          component: () => import("../../views/subscriptions/AddSubscription.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            reload:this.getSubscriptions,
          },
        });
      },
      editSubscription(subscription) {
        const panel1Handle = this.$showPanel({
          component: () => import("../../views/subscriptions/EditSubscription.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            subscription: subscription,
            reload:this.getSubscriptions,
          },
        });
  
        panel1Handle.promise.then((result) => {});
      },
      error: function (message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      warning: function (message) {
        this.$toast.warning(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function (message) {
        this.$toast.success(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
  </script>
  
  <style>
  </style>